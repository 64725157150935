import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';



import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const initial = {
    type_group: "",
    booking_type_id: "",
    type_booking: ""
}

export default function WorkTypeDialog({ disabled, onHandle }) {

    const [formState, setFormState] = useState(initial)

    const [group_work_types, set_group_work_types] = useState([])

    const [workTypes, setWorkTypes] = useState([
        {
            group_works: "T0001",
            group_name: "ทัวร์ไทย",
            work_types: [{ work_id: "W0001", work_name: "ทัวร์ส่วนตัว" }, { work_id: "W0002", work_name: "ทัวร์บริษัท" }]
        },
        {
            group_works: "T0002",
            group_name: "ทัวร์ต่างชาติ",
            work_types: [{ work_id: "W2001", work_name: "ทัวร์จีน" }, { work_id: "W2003", work_name: "ทัวร์เวียดนาม", work_name: "ทัวร์รัฐเซีย" }]
        }
    ])

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setFormState(initial)
    }
    const handleSave = () => {
        setOpen(false)
        onHandle({ type_group: formState.type_group, type_booking: formState.type_booking })
        setFormState(initial)
    }


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormState(s => {
            return { ...s, [name]: value }
        })
    }
    const handleChangeGroup = (id) => {
        if (id !== "") {
            const work = workTypes.find(s => s.group_works === id)
            set_group_work_types(work.work_types)
            setFormState(s => {
                return { ...s, type_group: work.group_name, booking_type_id: work.group_works }
            })
        } else {
            set_group_work_types([])
            setFormState(s => {
                return { ...s, type_group: "", booking_type_id: "", type_booking: "" }
            })
        }
    }
    // const handleChangeGroupid = (e) => {
    //     const { name, value } = e.target
    //     setFormState(s => {
    //         return { ...s, group_work_type: value, }
    //     })
    // }

    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><SearchIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>กลุ่มประเภทงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box component="form" >
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={6}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth size="small">
                                        <InputLabel htmlFor="demo-dialog-native">กลุ่มประเภท</InputLabel>
                                        <Select
                                            name='booking_type'
                                            value={formState.booking_type_id}
                                            onChange={(e) => {
                                                handleChangeGroup(e.target.value)
                                            }}
                                            input={<OutlinedInput label="กลุ่มประเภท" id="demo-dialog-native" />}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                workTypes.map((work, id) => {
                                                    return (
                                                        <MenuItem key={"G" + id} value={work.group_works}>{work.group_name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth size="small">
                                        <InputLabel id="demo-dialog-select-label">ประเภทงาน</InputLabel>
                                        <Select
                                            labelId="demo-dialog-select-label"
                                            id="demo-dialog-select"
                                            value={formState.type_booking}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="ประเภทงาน" />}
                                            name="type_booking"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                group_work_types.map((work, id) => {
                                                    return (
                                                        <MenuItem key={"W" + id} value={work.work_name}>{work.work_name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}