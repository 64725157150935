import React, { useEffect, useState } from 'react'

import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { styled } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
export default function HomeBooking() {

  const [search, setSearch] = useState("")
  const [bookings, setBookings] = useState([])


  useEffect(() => {
    axios.get(`https://booking.sitgps.com/booking/get_all_booking`)
      .then(res => {
        console.log('res.data?.message', res.data?.message)
        if (res.data?.message === null) {
          setBookings([])
        } else {
          setBookings(res.data)
        }
      })
  }, [])


  const funcGetAllBooking = () => {
    axios.get(`https://booking.sitgps.com/booking/get_all_booking`)
      .then(res => {
        if (res.data?.message === null) {
          setBookings([])
        } else {
          setBookings(res.data)
        }
      })
  }


  const navigation = useNavigate()

  const onCreate = () => {
    navigation("create")
  }
  const onOverView = () => {
    navigation("overview")
  }
  const onUpdate = (booking_id) => {
    navigation(`update/${booking_id}`)
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };
  const handleSnackWithAction = ({ message, data, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  console.log('data', data)
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };

  const onHandleBooking = (d) => {
    const data = {
      "booking_id": d.booking_id,
    }
    axios.delete(`https://booking.sitgps.com/booking/book_h`, { data: data })
      .then(res => {
        handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
        funcGetAllBooking()
      })
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setSearch(value)

  }

  const onNavToReport = () => {
    navigation("report")
  }
  const onNavToSettings = () => {
    navigation("settings")
  }

  return (
    <div>
      <Box sx={{ padding: "10px" }}>
        <Stack spacing={1}>
          <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Box>
              <TextField size="small" label='ค้นหา'
                value={search}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box>
              <Stack spacing={1} direction="row">
                <IconButton onClick={onCreate} ><PostAddOutlinedIcon /></IconButton>
                <IconButton onClick={onOverView} ><ViewListTwoToneIcon /></IconButton>
                <IconButton onClick={onNavToReport} ><AssessmentIcon /></IconButton>
                <IconButton onClick={onNavToSettings} ><SettingsIcon /></IconButton>
              </Stack>
            </Box>
          </Stack>
          <Box>
            <Table aria-label="booking table">
              <TableHead>
                <TableRow>
                  <TableCell align="celeftnter" width={"120px"} >เลขที่ใบจอง</TableCell>
                  <TableCell align="celeftnter" width={"120px"} >กลุ่มประเภท</TableCell>
                  <TableCell align="celeftnter" width={"120px"} >ประเภท</TableCell>
                  <TableCell align="right" width={"120px"}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.filter((val) => val?.booking_id?.toUpperCase().includes(search.toUpperCase())).map((booking, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.booking_id}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.type_group}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.type_booking}</TableCell>
                    <TableCell align="right">
                      <Stack spacing={1} direction="row" justifyContent="end">
                        <IconButton onClick={() => onUpdate(booking.booking_id)} size="small"><EditNoteIcon /></IconButton>
                        <IconButton onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลใบจอง", data: booking, onHandle: () => onHandleBooking({ booking_id: booking.booking_id }) }) }} size="small"><DeleteOutlineIcon /></IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </Box >
    </div >
  )
}
