import styled from '@emotion/styled';
import { Container, Divider, Grid, TextField, Typography, Stack, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState, Fragment } from 'react'
import { Api } from '../../components/api';
import Page from '../../components/Page'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { Link } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { fDateTo } from '../../utils/formatTime';
import HasPermission from '../../haspermission/HasPermission';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCerDlt from './AddCerDlt';
import EditCerDlt from './EditCerDlt';
import ViewCerDlt from './ViewCerDlt';

import DltDownloadPDF from './DltDownloadPDF';

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function DltCer({ account, permissions }) {
    const { cocode } = account

    const [mode, setMode] = useState("docno")
    const [dltCers, setDltCers] = useState([])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };
    const DeviceSchema = Yup.object().shape({

        docno: mode === "docno" ? Yup.string().required('Docno is required').nullable() : Yup.string().notRequired(),
        dltdate: mode === "dltdate" ? Yup.string().required('dltdate is required').nullable() : Yup.string().notRequired(),

    })
    const formik = useFormik({
        initialValues: {
            docno: '',
            dltdate: new Date(),
        },
        validationSchema: DeviceSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting()
            getCer()
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;


    const getCer = () => {
        if (mode === "docno") {
            Api.get(`/v0/gps/certificates?cocode=${cocode}&docno=${values.docno}`)
                .then(res => {
                    setDltCers(res.data)
                })
                .catch(err => {
                    setDltCers([])
                    handleSnackbar({ message: "ไม่พบข้อมูล", variant: "error" })
                })
            return
        }

        if (mode === "chassis") {
            Api.get(`/v0/gps/certificatesByChassis?cocode=${cocode}&chassis=${values.chassis}`)
                .then(res => {
                    setDltCers(res.data)
                })
                .catch(err => {
                    handleSnackbar({ message: "ไม่พบข้อมูล", variant: "error" })
                    setDltCers([])
                    // window.open(`/report/dlt_cer/print_dltdate/${fDateTo(values.dltdate, "yyyy-MM-dd")}`, "_blank") 
                })
            return
        }
        Api.get(`/v0/gps/certificatesBydltdate?cocode=${cocode}&dltdate=${fDateTo(values.dltdate, "yyyy-MM-dd")}`)
            .then(res => {
                setDltCers(res.data)
            })
            .catch(err => {
                handleSnackbar({ message: "ไม่พบข้อมูล", variant: "error" })
                setDltCers([])
                // window.open(`/report/dlt_cer/print_dltdate/${fDateTo(values.dltdate, "yyyy-MM-dd")}`, "_blank") 
            })
        return
    }
    const handleDel = (dltCer) => {
        const data = JSON.stringify(dltCer)
        Api.delete(`v0/gps/certificates`, { data: data })
            .then((res) => {
                handleSnackbar({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getCer()
            })
            .catch((err) => {
                handleSnackbar({ variant: 'error', message: "ลบข้อมูลไม่สำเร็จ" });
            })
    }

    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h4">DLT certificate </Typography>
                        <Button onClick={() => { setMode("docno") }} variant={mode === "docno" ? "contained" : "outlined"}> docno </Button>
                        <Button onClick={() => { setMode("dltdate") }} variant={mode === "dltdate" ? "contained" : "outlined"}> dltdate </Button>
                        <Button onClick={() => { setMode("chassis") }} variant={mode === "chassis" ? "contained" : "outlined"}> chassis </Button>
                    </Stack>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                <Box width={400} margin="auto" padding={2}
                                // border={1} borderRadius={1}
                                >
                                    {
                                        mode === "docno" && (
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="docno"
                                                            {...getFieldProps('docno')}
                                                            error={Boolean(touched.docno && errors.docno)}
                                                            helperText={touched.docno && errors.docno}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} justifyContent="center">
                                                        <LoadingButton
                                                            fullWidth
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="info"
                                                            loading={isSubmitting}
                                                        >
                                                            Search
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                    {
                                        mode === "chassis" && (
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="chassis"
                                                            {...getFieldProps('chassis')}
                                                            error={Boolean(touched.chassis && errors.chassis)}
                                                            helperText={touched.chassis && errors.chassis}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} justifyContent="center">
                                                        <LoadingButton
                                                            fullWidth
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="info"
                                                            loading={isSubmitting}
                                                        >
                                                            Search
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                    {
                                        mode === "dltdate" && (
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10}>
                                                        <MobileDatePicker
                                                            ampm={false}
                                                            // disablePast={true}
                                                            label="วันที่"
                                                            allowKeyboardControl={false}
                                                            value={values.dltdate}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('dltdate', val);
                                                                // }
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                size="small"
                                                                {...params}
                                                                {...getFieldProps('dltdate')}
                                                                fullWidth
                                                                placeholder="DD/MM/YYYY"
                                                                autoComplete="off"
                                                                error={Boolean(touched.dltdate && errors.dltdate)}
                                                                helperText={touched.dltdate && errors.dltdate} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} justifyContent="center">
                                                        <LoadingButton
                                                            fullWidth
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="info"
                                                            loading={isSubmitting}
                                                        >
                                                            Search
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>docno</TableCell>
                                <TableCell>customer_name</TableCell>
                                <TableCell>vehicle_id</TableCell>
                                <TableCell>unit_id</TableCell>
                                <TableCell>สถานะ</TableCell>
                                <TableCell sx={{ justifyContent: "end" }}>
                                    <Stack direction="row" justifyContent="end" spacing={1}>
                                        <HasPermission permissions={permissions} scope={["create"]}
                                        // RenderError={() =>

                                        // }
                                        >
                                            <AddCerDlt account={account} getCer={getCer} />
                                        </HasPermission>
                                        <HasPermission permissions={permissions} scope={["download"]}
                                        // RenderError={() =>

                                        // }
                                        >
                                            <DltDownloadPDF dltCers={dltCers} />
                                            {/* <IconButton size="small"><FileDownloadIcon fontSize="small" /></IconButton> */}
                                        </HasPermission>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dltCers.map((cer, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{cer.docno}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "185px" }}>{cer.customer_name}</TableCell>
                                            <TableCell>{cer.vehicle_id}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap", overflow: "auto", maxWidth: "185px" }}>{cer.unit_id}</TableCell>
                                            <TableCell>{cer.revokedate === null ? `ใช้งาน` : `ยกเลิก ${fDateTo(cer.revokedate, "dd/MM/yyyy")}`}</TableCell>
                                            <TableCell>
                                                <Stack spacing={1} direction="row" justifyContent="end">
                                                    <HasPermission permissions={permissions} scope={["update"]}>
                                                        <EditCerDlt cer={cer} getCer={getCer} account={account} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["print"]}>
                                                        <ViewCerDlt cer={cer} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["delete"]}>
                                                        <IconButton size="small" onClick={() => {
                                                            handleSnackWithAction({ message: "ต้องการที่จะลบ?", onHandle: () => { handleDel(cer) } })
                                                        }}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </HasPermission>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </MainStyle>
            </Container>
        </Page>
    )
}
