import React, { useRef, useState } from 'react'
import { Box, Grid, Stack, TextField, Button, Divider, MenuItem, IconButton, Menu } from '@mui/material'
import axios from 'axios'
import { fDateTo } from '../../utils/formatTime'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import ListTileLayer from '../../layouts/ListTileLayer'
import LayersIcon from '@mui/icons-material/Layers';
import { styled } from '@mui/styles'
import MarkerLabel from './MarkerLabel'

export default function SitWorkReport() {
    const [rowsWorks, setRowsWorks] = useState([])
    const [userid, setUserid] = useState(`APPLE@000241.d11574e04d7c43ebb52190d9e8292ef8.0438`)
    const [datestart, setDatestart] = useState("2024-09-12 00:00:00")
    const [dateend, setDateend] = useState("2024-09-12 23:59:59")

    const getDataWorks = () => {
        axios.get(`https://booking.sitgps.com/working?userid=${userid}&datestart=${datestart}&dateend=${dateend}`)
            .then((res) => {
                setRowsWorks(res.data)
            })
    }


    return (

        <Box>
            <Box sx={{ padding: "10px" }}>
                <Stack direction="row" spacing={1}>
                    <TextField size="small" label="username" value={userid} onChange={(e) => { setUserid(e.target.value) }} />
                    <TextField size="small" label="วันที่" value={datestart} onChange={(e) => { setDatestart(e.target.value) }} />
                    <TextField size="small" label="ถึงวันที่" value={dateend} onChange={(e) => { setDateend(e.target.value) }} />
                    <Button size="small" variant="outlined" onClick={getDataWorks}>ค้นหา</Button>
                </Stack>
            </Box>
            <Divider />
            {
                rowsWorks.map((work, index) => {
                    return (
                        <Box justifyContent="center" display="flex">
                            <Box sx={{ backgroundColor: "whitesmoke", borderRadius: "10px", padding: "10px", margin: "10px" }}>
                                <Box >
                                    <Box>
                                        {`เลขที่งาน : ${work.workid}`}
                                    </Box>
                                    <Box>
                                        {`เวลาที่เริ่ม : ${fDateTo(work.workstart, "dd/MM/yyyy HH:mm:ss")}`}
                                    </Box>
                                    <Box>
                                        {`ถึงเวลา : ${fDateTo(work.workstop, "dd/MM/yyyy HH:mm:ss")}`}
                                    </Box>
                                    <Box sx={{ width: "500px" }}>
                                        <Box>
                                            {
                                                work.data.map((row, index) => {
                                                    return (
                                                        <Box key={`work${index}`} sx={{ backgroundColor: "white", padding: "10px", margin: "10px" }}>
                                                            <Box>
                                                                {`เวลา : ${fDateTo(row.eventstart, "dd/MM/yyyy HH:mm:ss")}`}
                                                            </Box>
                                                            <Box>
                                                                {`เลขที่เหตุการณ์ : ${row.eventid}`}
                                                            </Box>
                                                            <Box>
                                                                {`รหัสเหตุการณ์ : ${row.eventcode}`}
                                                            </Box>
                                                            <Box sx={{ backgroundColor: "white", borderRadius: "10px", padding: "10px", margin: "10px" }}>
                                                                <TypeComponent row={row} />
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Divider />
                            </Box>
                        </Box>
                    )
                })
            }
        </Box >
    )
}




const TypeComponent = ({ row }) => {
    if (row.eventcode === "IMG") {

        const data = JSON.parse(row.eventvalue)
        const imgSrc = `data:${data.type};base64,${data.value}`;
        return (
            <Box justifyContent="center" display="flex">
                <Box sx={{ width: "300px" }}>
                    <img src={imgSrc} width="300px" style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} alt='logo' />
                </Box>
            </Box>
        )
    }
    if (row.eventcode === "CHECKIN") {
        const data = JSON.parse(row.eventvalue)
        return (
            <Box justifyContent="center" display="flex">
                <Box sx={{ width: "100%" }}>
                    <MapComponent data={data} />
                </Box>
            </Box>
        )
    }
    const data = JSON.parse(row.eventvalue)
    return (
        <>
            {Object.keys(data).map((innerAttr, index) => {
                return (
                    <Box key={`eventvalue-${index}`}>
                        {`${innerAttr} : ${data[innerAttr]}`}
                    </Box>
                )
            })}
        </>
    )
}

const MapComponent = ({ data }) => {

    const { lat, lng, address } = data
    const center = [lat, lng];
    const zoom = 6;
    const refDropDownMapStyle = useRef(null);
    const refMapStyle = useRef(null);
    // const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
    const [selectedMapStyle, setSelectedMapStyle] = useState("LongdoMap")

    const [openDropDown, setDropDown] = useState(false);

    return (
        <Box sx={{
            position: "relative"
        }}>
            <MapContainer
                center={center}
                zoom={zoom}
                minZoom={17}
                zoomControl={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                attributionControl={false}
                whenCreated={maps => {
                }}
                style={{
                    height: '300px',
                    width: '100%',
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                    zIndex: 1,
                }}
                boundsOptions={{
                    // paddingBottomRight: [250, 0],
                    paddingTopLeft: [150, 0]
                }}
            >
                <TileLayer ref={refMapStyle}
                    url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                {/* <ZoomControl position='topright' /> */}


                <MarkerLabel
                    position={[lat, lng]}
                    label={
                        <Box sx={{ padding: "5px", borderRadius: "10px", backgroundColor: "whitesmoke", width: "250px" }}>
                            {
                                Object.keys(data).map((innerAttr, index) => {
                                    return (
                                        <Box key={`eventvalue-${index}`}>
                                            {`${innerAttr} : ${data[innerAttr]}`}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    }
                />

            </MapContainer >
            <Box style={
                {
                    position: "absolute",
                    display: 'block',
                    backdropFilter: 'blur(10px)',
                    border: "2px solid rgba(0,0,0,0.2)",
                    borderRadius: 5,
                    bottom: 10,
                    right: 10,
                    width: 32,
                    height: 32,
                    zIndex: 2,
                }
            }
                ref={refDropDownMapStyle}
            >
                <IconButton edge="end" size="small"
                    style={{ backgroundColor: 'white', borderRadius: 5, }}
                    onClick={() => {
                        setDropDown(!openDropDown)
                    }}>
                    <LayersIcon fontSize="inherit" />
                </IconButton>
                <Menu
                    open={openDropDown}
                    anchorEl={refDropDownMapStyle.current}
                    onClose={() => setDropDown(!openDropDown)}
                    PaperProps={{
                        sx: { width: 200, maxWidth: '100%' }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {
                        ListTileLayer.map((value, index) =>
                            <MenuItem
                                sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                                onClick={
                                    () => {
                                        setDropDown(!openDropDown)
                                        if (refMapStyle.current) {
                                            refMapStyle.current.setUrl(value.url);
                                            setSelectedMapStyle(value.id)
                                            // localStorage.setItem('selectedMapStyle', value.id)
                                        }
                                    }
                                }
                                key={'MenuItem' + index}
                            >
                                {
                                    value.name
                                }
                            </MenuItem>
                        )
                    }
                </Menu>
            </Box>
        </Box >
    )
}