import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------
function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}
export function fDateTo(date, f) {
  if (!isDateValid(date)) {
    return ''
  }
  return format(new Date(date), f);
}
export function fDateZTo(date, f) {
  return format(new Date(moment(date, "YYYY-MM-DDTHH:mm:sssz").format("YYYY-MM-DD HH:mm:ss")), f);
}
export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}
export function fDateTimeThai(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm');
}
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateTimeToString(date) {
  return format(date, 'dd/MM/yyyy HH:mm');
}