import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import OverviewEmployee from './Employee/OverviewEmployee';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import * as Yup from 'yup';
import { fDateTo } from '../../utils/formatTime';
import OverviewCars from './Cars/OverviewCars';

export default function ButtonDialogWork({ booking_id, trip_id, station_start_datetime, station_end_datetime, onHandleSubmit }) {
    const initialForm = {
        "booking_id": booking_id,
        "trip_id": trip_id,
        "type_car": "",
        "vehicle_id": "",
        "driver_id": [],
        "reception_id": [],
        "station_start": "",
        "station_start_datetime": station_start_datetime,
        "station_start_flight": "",
        "station_end": "",
        "station_end_datetime": station_end_datetime,
        "station_end_flight": "",
        "note": ""
    }
    const FormSchema = Yup.object().shape({

    });
    const formik = useFormik({
        initialValues: {
            ...initialForm
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setValues, resetForm }) => {
            axios.post(`https://booking.sitgps.com/booking/book_d`, {
                "booking_id": booking_id,
                "trip_id": trip_id,
                "type_car": values.type_car,
                "vehicle_id": values.vehicle_id,
                "driver_id": values.driver_id,
                "reception_id": values.reception_id,
                "station_start": values.station_start,
                "station_start_datetime": (values.station_start_datetime).replaceAll("T", " ").replaceAll(".000Z", ""),
                "station_end_datetime": (values.station_end_datetime).replaceAll("T", " ").replaceAll(".000Z", ""),
                "station_start_flight": values.station_start_flight,
                "station_end": values.station_end,
                "station_end_flight": values.station_end_flight,
                "note": values.note
            })
                .then(res => {
                    handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
                    setOpen(false)
                    onHandleSubmit(booking_id)
                    resetForm()
                })
                .catch(err => {
                    console.log('err', err)
                })
        }
    });
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        formik.resetForm()
        setOpen(false)
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };

    const handleChangeReception = (reception, type) => {
        if (type === "add") {
            const d = formik.values.reception_id.concat(reception)
            formik.setValues({ ...formik.values, reception_id: d })
        } else {
            const d = formik.values.reception_id.filter(v => v !== reception)
            formik.setValues({ ...formik.values, reception_id: d })
        }
    }
    const handleChangeDriver = (driver, type) => {
        if (type === "add") {
            const d = formik.values.driver_id.concat(driver)
            formik.setValues({ ...formik.values, driver_id: d })
        } else {
            const d = formik.values.driver_id.filter(v => v !== driver)
            formik.setValues({ ...formik.values, driver_id: d })
        }
    }
    const handleChangeCars = (car) => {
        formik.setValues({ ...formik.values, vehicle_id: car })
    }

    return (
        <Box>
            <IconButton onClick={handleOpen} size="small"><AddIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียด ใบงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack>
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} md={12}>
                                                        <TextField size="small" label='ทะเบียนรถ' placeholder='ทะเบียนรถ'
                                                            disabled
                                                            {...formik.getFieldProps('vehicle_id')}
                                                            error={Boolean(formik.touched.vehicle_id && formik.errors.vehicle_id)}
                                                            helperText={formik.touched.vehicle_id && formik.errors.vehicle_id}
                                                            name='vehicle_id' fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" >
                                                                        <OverviewCars onHandle={(e) => handleChangeCars(e)} />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='สถานที่รับ'
                                                    {...formik.getFieldProps('station_start')}
                                                    error={Boolean(formik.touched.station_start && formik.errors.station_start)}
                                                    helperText={formik.touched.station_start && formik.errors.station_start}
                                                    name='station_start'
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => {
                                                                }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField fullWidth size="small" type="datetime-local" name='station_start_datetime'  {...formik.getFieldProps('station_start_datetime')} value={(formik.values?.station_start_datetime).replaceAll("T", " ").replaceAll(".000Z", "")} />
                                            </Grid>
                                            {/* <Grid item xs={12} md={12}>
                                                <TextField fullWidth size="small" type="datetime-local" name='datetime_end'  {...formik.getFieldProps('datetime_end')} value={formik.values?.datetime_end} />
                                            </Grid> */}
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='flight' placeholder='XX-XXXXX'
                                                    {...formik.getFieldProps('station_start_flight')}
                                                    error={Boolean(formik.touched.station_start_flight && formik.errors.station_start_flight)}
                                                    helperText={formik.touched.station_start_flight && formik.errors.station_start_flight}
                                                    name='station_start_flight' fullWidth />
                                            </Grid>

                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='สถานที่ส่ง'
                                                    {...formik.getFieldProps('station_end')}
                                                    error={Boolean(formik.touched.station_end && formik.errors.station_end)}
                                                    helperText={formik.touched.station_end && formik.errors.station_end}
                                                    name='station_end'
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => {
                                                                }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField fullWidth size="small" type="datetime-local" name='station_end_datetime'  {...formik.getFieldProps('station_end_datetime')} value={(formik.values?.station_end_datetime).replaceAll("T", " ").replaceAll(".000Z", "")} />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='flight'
                                                    {...formik.getFieldProps('station_end_flight')}
                                                    error={Boolean(formik.touched.station_end_flight && formik.errors.station_end_flight)}
                                                    helperText={formik.touched.station_end_flight && formik.errors.station_end_flight}
                                                    name='station_end_flight'
                                                    fullWidth />
                                            </Grid>
                                            {/* ************************พนักงานขับรถ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <OverviewEmployee
                                                    onHandle={(e, t) => handleChangeDriver(e, "add")}

                                                />
                                            </Grid>
                                            {
                                                formik.values?.driver_id?.map((driver, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12}>
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField size="small" disabled label='userid' placeholder='userid' value={driver} fullWidth />
                                                                <Button onClick={() => handleChangeDriver(driver, "del")}>ลบ</Button>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {/* ************************พนักงานขับรถ************************ */}
                                            {/* ************************พนักงานต้อนรับ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <OverviewEmployee
                                                    onHandle={(e, t) => handleChangeDriver(e, t)}

                                                />
                                            </Grid>
                                            {
                                                formik.values?.reception_id?.map((reception, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12}>
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField size="small" disabled label='userid' placeholder='userid' value={reception} fullWidth />
                                                                <Button onClick={() => handleChangeReception(reception, "del")}>ลบ</Button>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {/* ************************พนักงานต้อนรับ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <TextField size="small"
                                                    {...formik.getFieldProps('note')}
                                                    error={Boolean(formik.touched.note && formik.errors.note)}
                                                    helperText={formik.touched.note && formik.errors.note}
                                                    name='note'
                                                    label='หมายเหตุ' multiline maxRows={3} minRows={3} fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </DialogContent >
                <DialogActions>
                    <Button onClick={() => { formik.submitForm() }}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
