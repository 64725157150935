import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'

export default function SettingEventsDialogUpdate({ onClose, open, getData, data }) {

    const [data_p, setData_p] = useState({
        ...data
    })
    useEffect(() => {
        setData_p({ ...data })
    }, [data])



    const onSubmit = () => {
        const data = {
            "event_code": `${data_p?.event_code}`,
            "event_name": `${data_p?.event_name}`,
            "type": `${data_p?.type}`,
            "note": `${data_p?.note}`,
            // "status": `${data_p?.status}`
        }
        axios.put(`https://booking.sitgps.com/booking/if_event`, data)
            .then(res => {
                onClose()
                getData()
            })
            .catch(error => {
                console.log('error', error)
            })
    }
    const onChange = (e) => {
        const { name, value } = e.target
        setData_p(d => {
            return { ...d, [name]: value }
        })
    }
    return (
        <div>
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>Events</DialogTitle>
                <DialogContent>
                    <Box marginTop="8px">
                        <Stack spacing={1}>

                            <Box>
                                <TextField size="small"
                                    value={data_p?.type}
                                    fullWidth select
                                    label="ประเภท"
                                    name='type'
                                    onChange={onChange}
                                >
                                    <MenuItem value={""}>กรุณาระบุ</MenuItem>
                                    <MenuItem value={"staff"}>{"staff"}</MenuItem>
                                    <MenuItem value={"vehicle"}>{"vehicle"}</MenuItem>
                                </TextField>
                            </Box>
                            <Box>
                                <TextField size="small" onChange={onChange} name="event_name" value={data_p?.event_name} fullWidth label="ชื่อ" />
                            </Box>
                            <Box>
                                <TextField size="small" onChange={onChange} name="note" multiline={true} rows={3} value={data_p?.note} fullWidth label="note" />
                            </Box>
                            <Box>
                                <Stack direction="row" spacing={1}>
                                    <Button variant="contained" onClick={onSubmit}>บันทึก</Button>
                                    <Button variant="outlined" onClick={onClose}>ยกเลิก</Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
