import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip } from '@mui/material'
import data from '@iconify/icons-eva/menu-2-fill';
import DialogEventsEmployee from '../DialogEventsEmployee';
import zIndex from '@mui/material/styles/zIndex';
import DialogViewWork from '../DialogViewWork';

export default function TableOverViewCars({
    dataFillter, handleEventClick, maxDate, year, month
}) {

    const [dataSelect, setDataSelect] = useState("")
    const [dateBetween, setDateBetween] = useState({
        vehicle_id: "",
        date_start: "",
        date_end: ""
    });

    const onSelect = ({ vehicle_id }) => {
        handleEventClick(vehicle_id)
    }




    const getBackgroundColor = (date, vehicle_id, ranges) => {
        const event = ranges?.find((event) =>
            date >= event.date_start && date <= event.date_end
        );
        return event ? (event?.booking_id ? '#678fd4' : event?.id ? '#99b4e2' : '#ccdaf1') :
            (dateBetween.date_start !== "" && dateBetween.vehicle_id === vehicle_id) ? date >= dateBetween.date_start && date <= (dateBetween.date_end !== "" ? dateBetween.date_end : dateBetween.date_start) ? "#e9e6ee" : 'white' : 'white';
    };



    const filterEventInfo = (date, ranges) => {
        if (ranges.length > 0) {
            return ranges.filter((d) => date >= d.date_start && date <= d.date_end)
        } else {
            return []
        }
    };

    const [isOpenEvents, setIsOpenEvents] = useState(false);

    const handleEventsOpen = (data) => {
        setIsOpenEvents(true)
    }
    const handleEventsClose = () => {
        setIsOpenEvents(false)
    }
    const handleEventsCancel = () => {
        setIsOpenEvents(false)
    }

    const [isOpenWork, setIsOpenWork] = useState(false)
    const [dataViewWork, setDataViewWork] = useState([])
    const handleViewWorkClose = () => {
        setIsOpenWork(false)
    }
    return (
        <>
            <DialogEventsEmployee open={isOpenEvents} onCancel={handleEventsCancel} onClose={handleEventsClose} data={dateBetween} />
            <DialogViewWork open={isOpenWork} onClose={handleViewWorkClose} data={dataViewWork} />
            {
                dataFillter.map((rowdata, index) => {
                    return (
                        <TableRow key={`car-row-${index}`}>
                            <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2, backgroundColor: "white" }}>
                                <Box marginX={"5px"}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Box>{`${rowdata.vehicle_id}`}</Box>
                                    </Stack>
                                </Box>
                            </TableCell>
                            {
                                (
                                    [...Array(maxDate)].map((value, index) => {
                                        const day = (index + 1).toString().padStart(2, "0")
                                        const data = filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data)
                                        return (
                                            <TableCell
                                                key={`car-TableCell-row-${index}`}
                                                onClick={() => {
                                                    if (data.length <= 0) {
                                                        const data = {
                                                            vehicle_id: rowdata.vehicle_id,
                                                            type_car: rowdata.standard,
                                                            date: `${year}-${month}-${day}`
                                                        }
                                                        onSelect(data)
                                                    } else {
                                                        setIsOpenWork(true)
                                                        setDataViewWork(filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data))
                                                    }
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                    position: 'sticky',
                                                    whiteSpace: "nowrap",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor: getBackgroundColor(`${year}-${month}-${day} 00:00:00`, rowdata.vehicle_id, rowdata.data),
                                                }}
                                            >
                                                <Box sx={{ zIndex: 2 }}>
                                                </Box>
                                            </TableCell>
                                        )
                                    })
                                )
                            }
                        </TableRow >
                    )
                })
            }
        </>
    )
}
