import React, { useEffect, useRef, useState } from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Stack, IconButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox } from '@mui/material'
import logo_dlt from '../../img/logo_dlt.png'
import sit_logo from '../../img/sit-logo.png'
import sit_stamp from '../../img/sit-stamp.png'
import supoj_signature from '../../img/supoj-signature.png'
import PhonChaNok_signature from '../../img/PhonChaNok-signature.png'
import { fDateTo } from '../../utils/formatTime';
import { makeStyles } from '@mui/styles';
import { isSafari } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) =>
({
    contentPage: {
        margin: '1cm auto',
        maxWidth: '21cm',
        // minHeight: '29.7cm',
        overflow: 'auto',
        border: '1px #D3D3D3 solid',
        background: 'white',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        padding: '1cm'
    },
    botttomPrint: {
        margin: '1.8cm auto',
    },
    contentPrint: {
        width: '18.4cm',
        marginLeft: '.4cm',
        // marginRight: '50px',
        minHeight: '24.5cm',
        color: 'black'
    },
    [`@media print`]: {
        // [`@media print`]: { margin: 0 },
        // body: { margin: '1.6cm' },
        contentPage: {
            // display: 'none'
            margin: 'initial',
            marginLeft: 'initial',
            width: 'initial',
            minHeight: 'initial',
            border: 'initial',
            background: 'initial',
            boxShadow: 'initial',
            padding: 'initial'
        },
        contentPrint: {
            width: '18.4cm',
            marginLeft: isSafari ? 'initial' : '.8cm',
            // marginRight: '50px',
            minHeight: '24.5cm',
        },
        botttomPrint: {
            display: 'none'
        },
    },
}))

export default function ViewCerDlt({ cer }) {

    useEffect(() => {
        function handleKeyDown(e) {
            if ((e.metaKey || e.ctrlKey) && e.key === 'p') {
                e.preventDefault();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
    }, [document])

    useEffect(() => {
        setDltCer(cer)
    }, [cer])

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [dltCer, setDltCer] = useState(cer)
    const [statePage, setStatePage] = React.useState({
        firstPage: true,
        secondPage: false,
    });
    const onClickOpen = () => {
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }
    const handleChange = (event) => {
        setStatePage({
            ...statePage,
            [event.target.name]: event.target.checked,
        });
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <IconButton size="small" onClick={onClickOpen}>
                <LocalPrintshopOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Box
                        ref={componentRef}
                        sx={{
                            background: "white",
                            paddingX: "0.5cm",
                            paddingTop: "0.5cm",
                            fontFamily: 'Sarabun',
                        }}
                        className="section-to-print"
                    >
                        {statePage.firstPage && (
                            <div className={classes.contentPage}>
                                <div className={classes.contentPrint}
                                >
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            marginTop: "0.8cm",
                                            marginBottom: "0.8cm",
                                            // fontSize : '16px'
                                        }}
                                    >
                                        หนังสือรับรองการติดตั้งเครื่องบันทึกข้อมูลการเดินทางของรถ
                                    </div>
                                    <div style={{
                                        border: '1px black solid',
                                        padding: '0.3cm',
                                        // maxHeight : '200mm',
                                        fontSize: '14px'
                                    }}>
                                        <div style={{
                                            textAlign: 'center'
                                        }}>
                                            หนังสือรับรองการติดตั้งเครื่องบันทึกข้อมูลการเดินทาง
                                        </div>
                                        <p>
                                            เลขที่หนังสือ {dltCer.docno}
                                        </p>
                                        <dd style={{ fontWeight: 'bold' }}>
                                            {dltCer.vender_name} <br />
                                            ที่อยู่ {dltCer.vender_add} <br />
                                            เบอร์โทรศัพท์ {dltCer.vender_tel}
                                        </dd>
                                        <p>
                                            ได้ติดตั้งเครื่องบันทึกข้อมูลการเดินทางของรถรายละเอียดดังนี้
                                        </p>
                                        <dd style={{ fontWeight: 'bold' }}>
                                            <p >การรับรองจากกรมการขนส่งทางบกเลขที่
                                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {dltCer.gpslicense}
                                                </label>
                                            </p>
                                            <p>
                                                ชนิด
                                                <label style={{ width: '345px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {dltCer.gpsmodel}
                                                </label>
                                                แบบ
                                                <label style={{ width: '80px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {dltCer.gpstype}
                                                </label>
                                            </p>
                                            <p>
                                                หมายเลขเครื่อง
                                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {dltCer.unit_id}
                                                </label>
                                            </p>
                                            <p>
                                                เครื่องอ่านบัตรชนิดแถบแม่เหล็ก ชนิด
                                                <label style={{ width: '150px', display: 'inline-block', marginLeft: '30px', fontWeight: 'normal' }}>
                                                    {dltCer.mcrmodel}
                                                </label>
                                                แบบ
                                                <label style={{ width: '80px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {dltCer.mcrtype}
                                                </label>
                                            </p>
                                            <p>
                                                วันที่ติดตั้ง
                                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                                    {fDateTo(dltCer.installdate, 'dd/MM/yyyy')}
                                                </label>
                                            </p>
                                            <p>
                                                ชื่อผู้ประกอบการขนส่ง / เจ้าของรถ
                                                <label style={{
                                                    marginLeft: '20px', fontWeight: 'normal'
                                                }}>
                                                    {dltCer.customer_name}
                                                </label>
                                            </p>
                                            <p>
                                                ที่อยู่
                                                <label style={{
                                                    marginLeft: '20px', fontWeight: 'normal'
                                                }}>
                                                    {dltCer.customer_add}
                                                </label>
                                            </p>
                                            <p>
                                                เบอร์โทร
                                                <label style={{
                                                    marginLeft: '20px', fontWeight: 'normal'
                                                }}>
                                                    {dltCer.customer_tel}
                                                </label>
                                            </p>
                                            <p>
                                                เลขทะเบียน
                                                <label style={{
                                                    marginLeft: '20px', width: '120px', display: "inline-block", fontWeight: 'normal'
                                                }}>
                                                    {dltCer.vehicle_id}
                                                </label>
                                                จังหวัด
                                                <label style={{
                                                    marginLeft: '20px', width: '150px', display: "inline-block", fontWeight: 'normal'
                                                }}>
                                                    {dltCer.vehicle_province}
                                                </label>
                                                ยี่ห้อ
                                                <label style={{
                                                    marginLeft: '20px', fontWeight: 'normal'
                                                }}>
                                                    {dltCer.vehicle_type}
                                                </label>
                                            </p>
                                            <p>
                                                หมายเลขคัสซี <label style={{
                                                    marginLeft: '20px', fontWeight: 'normal'
                                                }}>
                                                    {dltCer.vehicle_chassis_no}
                                                </label>
                                            </p>
                                            <p>
                                                หมายเหตุ
                                                <label style={{
                                                    marginLeft: '20px', marginRight: '20px', fontWeight: 'normal', borderBottom: '1px black dotted',
                                                    width: '80%', display: "inline-block"
                                                }}>

                                                </label>
                                            </p>
                                        </dd>
                                        <div style={{ fontWeight: 'initial', fontSize: '10.7px' }}>
                                            <dd>
                                                <p>
                                                    ขอรับรองว่าเครื่องบันทึกข้อมูลการเดินทางของรถดังกล่าวข้างต้นมีคุณลักษนะและระบบการทำงานที่ได้รับรองจากกรมการขนส่งทางบก
                                                </p>
                                                <p>
                                                    กรณีเครื่องบันทึกข้อมูลการเดินทางของรถมีคุณลักษณะหรือระบบการทำงานไม่เป็นไปตามที่กรมขนส่งทางบกได้ให้การรับรองหรือมีการรายงานข้อมูลไม่ตรง
                                                </p>
                                            </dd>
                                            <p>
                                                กับข้อเท็จจริงหรือไม่สามารถรายงานข้อมูลได้ตามที่กรมการขนส่งทางบกกำหนด {dltCer.vender_name} ยินยอมรับความผิดชอบ
                                            </p>
                                            <p>
                                                ความเสียหายที่เกิดขึ้นต่อเจ้าของรถหรือผู้ประกอบการขนส่งได้ซื้อหรือให้บริการเครื่องบันทึกข้อมูลการเดินทางของรถดังกล่าว
                                            </p>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}>
                                                    <p>
                                                        ออกให้ ณ วันที่ {fDateTo(dltCer.issuedate, 'dd/MM/yyy')}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={4}
                                                    style={
                                                        {
                                                            position: "relative"
                                                        }
                                                    }
                                                >
                                                    <img
                                                        style={{
                                                            position: "absolute",
                                                            right: "202px",
                                                            bottom: -23
                                                        }}
                                                        src={`${sit_stamp}`}
                                                        width={"145px"}
                                                        alt={'logo_dlt'}
                                                    />
                                                    <p>
                                                        ลงชื่อ <label style={{
                                                            fontWeight: 'normal', borderBottom: '1px black dotted',
                                                            width: '80%', display: "inline-block",
                                                            textAlign: "center",
                                                            position: "relative"
                                                        }}>
                                                            {
                                                                dltCer.issueby === "คุณสุพจน์ ทักษ์ประดิษฐ์" && supoj_signature && (
                                                                    <img
                                                                        src={`${supoj_signature}`}
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "5px",
                                                                            bottom: -5
                                                                        }}
                                                                        height={"50px"}
                                                                        alt={'logo_dlt'}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                dltCer.issueby === "คุณพรชนก โภคณิตถานนท์" && (
                                                                    <img
                                                                        src={`${PhonChaNok_signature}`}
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "5px",
                                                                            bottom: -12
                                                                        }}
                                                                        height={"50px"}
                                                                        alt={'logo_dlt'}
                                                                    />
                                                                )
                                                            }
                                                        </label>
                                                        <p
                                                            style={{
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            ( {dltCer.issueby} )
                                                        </p>
                                                        <p
                                                            style={{
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            {dltCer.vender_name}
                                                        </p>
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div style={{ fontWeight: 'initial', fontSize: '8px' }}>
                                        <p>
                                            หมายเหตุ :
                                        </p>
                                        <p>
                                            1.ชนิดและแบบของเครื่องบันทึกข้อมูลการเดินทางของรถและเครื่องอ่านบัตรชนิดแถบแม่เหล็ก ให้เป็นไปตามรายละเอียดที่ได้รับการรับรองจากกรมการขนส่งทางบก
                                        </p>
                                        <p>
                                            2.กรณีเป็นการติดตั้งเครื่องใหม่ทดแทนของเดิมให้ระบุรายละเอียดของเครื่องบันทึกข้อมูลการเดินทางของรถเครื่องเดิมในช่องหมายเหตุ เช่น ผู้ให้บริการเดิมชนิดและแบบเดิมหมายเลขเดิม
                                        </p>
                                    </div>

                                </div >
                            </div >
                        )}
                        {statePage.secondPage && (
                            <div className={classes.contentPage}>
                                <div className={classes.contentPrint} style={{ paddingTop: '4cm' }}>
                                    <div style={{
                                        border: '1px black solid',
                                        height: '301px',
                                        fontSize: '14px'
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Box sx={{ display: 'inline-block', minWidth: "151px" }}>
                                                <Box sx={{ height: "150px", borderBottom: '1px black solid', borderRight: '1px black solid', paddingTop: '8px', paddingLeft: '1px' }}>
                                                    <img
                                                        src={`${logo_dlt}`}
                                                        width="148px"
                                                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={'logo_dlt'}
                                                    // loading="lazy"
                                                    />
                                                </Box>
                                                <Box sx={{ height: "150px", borderRight: '1px black solid', paddingTop: '60px', paddingLeft: '4px' }} >
                                                    <img
                                                        src={`${sit_logo}`}
                                                        width="140px"
                                                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={'sit_logo'}
                                                    // loading="lazy"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'inline', textAlign: 'start', padding: '20px' }} justifyContent="flex-start">
                                                <p >การรับรองจากกรมการขนส่งทางบกเลขที่
                                                    <label style={{ paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '270px', display: "inline-block" }}>
                                                        {dltCer.gpslicense}
                                                    </label>
                                                </p>
                                                <p>
                                                    ชนิด
                                                    <label style={{ paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '240px', display: "inline-block" }}>
                                                        {dltCer.gpsmodel}
                                                    </label>
                                                    {/* &nbsp;&nbsp;  */}
                                                    แบบ
                                                    <label style={{ paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '206px', display: "inline-block" }}>
                                                        {dltCer.gpstype}
                                                    </label>
                                                </p>
                                                <p>
                                                    หมายเลขเครื่อง
                                                    <label style={{ paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '410px', display: "inline-block" }}>
                                                        {dltCer.unit_id}
                                                    </label>
                                                </p>
                                                <p>
                                                    เลขทะเบียน
                                                    <label style={{
                                                        paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '130px', display: "inline-block"
                                                    }}>
                                                        {dltCer.vehicle_id}
                                                    </label>
                                                    จังหวัด
                                                    <label style={{
                                                        paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '260px', display: "inline-block"
                                                    }}>
                                                        {dltCer.vehicle_province}
                                                    </label>
                                                </p>
                                                <p>
                                                    หมายเลขคัสซี <label style={{
                                                        paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '415px', display: "inline-block"
                                                    }}>
                                                        {dltCer.vehicle_chassis_no}
                                                    </label>
                                                </p>
                                                <p>
                                                    ผู้ให้บริการระบบติดตามรถ <label style={{
                                                        paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '345px', display: "inline-block"
                                                    }}>
                                                        {dltCer.vender_name}
                                                    </label>
                                                </p>
                                                <p>
                                                    วันที่ติดตั้ง
                                                    <label style={{
                                                        paddingLeft: '20px', fontWeight: 'normal', borderBottom: '1px black dotted', width: '440px', display: "inline-block"
                                                    }}>
                                                        {fDateTo(dltCer.installdate, 'dd/MM/yyy')}
                                                    </label>
                                                </p>
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Box >
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox checked={statePage.firstPage} onChange={handleChange} name="firstPage" />}
                        label="หน้า 1"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="end"
                        control={<Checkbox checked={statePage.secondPage} onChange={handleChange} name="secondPage" />}
                        label="หน้า 2"
                        labelPlacement="end"
                    />
                    <Button
                        style={{
                            margin: '5px'
                        }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                            handlePrint()
                        }}>
                        print
                    </Button>
                    <Button
                        style={{
                            margin: '5px'
                        }}
                        variant="contained"
                        onClick={() => {
                            onClickClose()
                        }}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
