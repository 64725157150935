import React, { useEffect, useState } from 'react';
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { fDateTo } from '../../utils/formatTime';
import _, { values } from 'lodash';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import TableCars from './TableCars';
import TableEmployee from './TableEmployee';
import { useNavigate } from 'react-router-dom';

const OverviewBooking = ({ userId }) => {
  const [page, setPage] = useState(1)
  const [pageMax, setPageMax] = useState(1)
  const [initialData, setInitialData] = useState([])
  const [loading, setloading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"))
  const [search, setSearch] = useState("")
  const [carSelect, setCarSelect] = useState("")

  const [mode, setMode] = useState("car")


  const navigate = useNavigate()

  useEffect(() => {
    setloading(true)
    const url = mode === "car" ? `https://booking.sitgps.com/booking/get_work_car?page=${page}&size=30&date=${year}-${month}` : `https://booking.sitgps.com/booking/get_work_employee?page=${page}&size=30&date=${year}-${month}`
    axios.get(url)
      .then(res => {
        const iFllter = res.data.data
        if (mode === "car") {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                "booking_id": v.booking_id,
                "trip_id": v.trip_id,
                "trip_company_id": v.trip_company_id,
                "trip_company_name": v.trip_company_name,
                "trip_type": v.trip_type,
                "date_start": (v.trip_start_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.trip_end_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "status": v.status
              }
            })
            const dataevents = events.map(v => {
              return {
                "id": v.id,
                "event_code": v.event_code,
                "event_name": v.event_name,
                "note": v.note,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "type": v.type,
                "ref": v.ref,
                "status": v.status
              }
            })
            return {
              "vehicle_id": d.vehicle_id,
              "color": d.color,
              "seat": d.seat,
              "size": d.size,
              "fuel": d.fuel,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        } else {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                "booking_id": v.booking_id,
                "trip_id": v.trip_id,
                "trip_company_id": v.trip_company_id,
                "trip_company_name": v.trip_company_name,
                "trip_type": v.trip_type,
                "date_start": (v.trip_start_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.trip_end_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "status": v.status
              }
            })
            const dataevents = events.map(v => {
              return {
                "id": v.id,
                "event_code": v.event_code,
                "event_name": v.event_name,
                "note": v.note,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "type": v.type,
                "ref": v.ref,
                "status": v.status
              }
            })

            return {
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "position": d.position,
              "department": d.department,
              "department2": d.department2,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        }
        setPageMax(res.data.pagesize)
        setSearch("")
        setloading(false)
      })
      .catch(error => {
        setloading(false)
      })
  }, [page, mode, month])


  const getDataBnE = () => {
    setloading(true)
    const url = mode === "car" ? `https://booking.sitgps.com/booking/get_work_car?page=${page}&size=30&date=${year}-${month}` : `https://booking.sitgps.com/booking/get_work_employee?page=${page}&size=30&date=${year}-${month}`
    axios.get(url)
      .then(res => {


        const iFllter = res.data.data
        if (mode === "car") {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                "booking_id": v.booking_id,
                "trip_id": v.trip_id,
                "trip_company_id": v.trip_company_id,
                "trip_company_name": v.trip_company_name,
                "trip_type": v.trip_type,
                "date_start": (v.trip_start_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.trip_end_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "status": v.status
              }
            })
            const dataevents = events.map(v => {
              return {
                "id": v.id,
                "event_code": v.event_code,
                "event_name": v.event_name,
                "note": v.note,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "type": v.type,
                "ref": v.ref,
                "status": v.status
              }
            })
            return {
              "vehicle_id": d.vehicle_id,
              "color": d.color,
              "seat": d.seat,
              "size": d.size,
              "fuel": d.fuel,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        } else {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                "booking_id": v.booking_id,
                "trip_id": v.trip_id,
                "trip_company_id": v.trip_company_id,
                "trip_company_name": v.trip_company_name,
                "trip_type": v.trip_type,
                "date_start": (v.trip_start_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.trip_end_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "status": v.status
              }
            })
            const dataevents = events.map(v => {
              return {
                "id": v.id,
                "event_code": v.event_code,
                "event_name": v.event_name,
                "note": v.note,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "type": v.type,
                "ref": v.ref,
                "status": v.status
              }
            })

            return {
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "position": d.position,
              "department": d.department,
              "department2": d.department2,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        }

        setPageMax(res.data.pagesize)
        setSearch("")
        setloading(false)
      })
      .catch(error => {
        setloading(false)
      })
  }

  const handleEventClick = (event) => {
    navigate("../create", { state: event })
    console.log('event', event)
  };

  const calculateMaxDate = () => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return lastDayOfMonth
  };

  const onSearch = (e) => {
    setSearch(e.target.value)
  }

  const initialdataFillter = mode === "car" ? initialData.filter((c, i) => c.vehicle_id.toLowerCase().includes(search.toLowerCase())) : initialData.filter((c, i) => c.userid.toLowerCase().includes(search.toLowerCase()))

  return (
    <Box sx={{ margin: "10px" }}>
      <Box>
        <Stack direction="row" spacing={1}>
          <TextField size="small" label="ค้นหา" onChange={onSearch} value={search} />
          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="buttongroup mode"
            color="success"
          >
            <Button variant={mode === "car" ? "contained" : "outlined"}
              onClick={() => {
                setMode("car")
                setPage(1)
                setInitialData([])
              }}>รถ</Button>
            <Button variant={mode === "employee" ? "contained" : "outlined"}
              onClick={() => {
                setMode("employee")
                setPage(1)
                setInitialData([])
              }}>พนักงาน</Button>
          </ButtonGroup>
          <TextField size="small"
            type="month"
            value={`${year}-${(month)}`}
            onChange={(e) => {
              const d = e.target.value
              const yearNmonth = d.split("-")
              setYear(yearNmonth[0])
              setMonth(yearNmonth[1])
              setPage(1)
            }} />
        </Stack>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 70px)" }}>
          <Table stickyHeader size="small" style={{ border: "none", userSelect: "none" }} >
            <TableHead>
              <TableRow>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2, textAlign: "center" }}> {mode === "car" ? "ทะเบียนรถ" : "รหัสพนักงาน"}</TableCell>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2, textAlign: "center" }} colSpan={calculateMaxDate()} >
                  {`${month}/${year}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 3, backgroundColor: "white" }}>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Button
                      onClick={() => {
                        setPage(p => { return p - 1 })
                      }}
                      disabled={page === 1}
                    >{`<`}</Button>
                    <Button
                      onClick={() => {
                        setPage(p => { return p + 1 })
                      }}
                      disabled={page === pageMax}
                    >{`>`}</Button>
                  </Stack>
                </TableCell>
                {
                  [...Array(calculateMaxDate())].map((value, index) => {
                    return (
                      <TableCell key={`Table-TableCell-${index}`} style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2 }}>{`${(index + 1).toString().padStart(2, "0")}`}</TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                mode === "car" && (
                  <TableCars userId={userId} getDataBnE={getDataBnE} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                )
              }
              {
                mode === "employee" && (
                  <TableEmployee userId={userId} getDataBnE={getDataBnE} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                )
              }
            </TableBody>
          </Table>
        </TableContainer >
      </Paper >
    </Box >
  );
};

export default OverviewBooking;
