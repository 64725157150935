import React, { useState } from 'react'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import { Box, TextField, Grid, InputAdornment, Button, Container, IconButton, MenuItem, Checkbox, FormLabel } from '@mui/material'
import AddCommentTwoToneIcon from '@mui/icons-material/AddCommentTwoTone';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSnackbar } from 'notistack';
import { ApiSms } from '../../components/api';
export default function () {
    const [custom, setCustom] = useState(false)
    const initialValues = {
        msn: "",
        password: "",
        msg: "",
        command: "",
        ip: "",
        port: ""
    }
    // const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
    const FormSchema = Yup.object().shape({
        // msn: Yup.string().matches(phoneRegExp, 'รูปแบบไม่ถูกต้อง').required('กรุณาใส่เบอร์โทรศัพท์'),
    });
    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setValues }) => {
            if (values.msn === "") {
                handleClickVariant({ variant: "error", message: "กรุณาใส่เบอร์โทรศัพท์" })
                return
            }
            if (values.password === "") {
                handleClickVariant({ variant: "error", message: "กรุณาใส่รหัสผ่าน" })
                return
            }
            if (values.msn.length !== 10) {
                handleClickVariant({ variant: "error", message: "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง" })
                return
            }
            if (values.command === "") {
                handleClickVariant({ variant: "error", message: "กรุณาใส่คำสั่ง" })
                return
            }
            if (values.command === "B00,1") {
                if (values.ip === "") {
                    handleClickVariant({ variant: "error", message: "กรุณาใส่ IP" })
                    return
                }
                if (values.port === "") {
                    handleClickVariant({ variant: "error", message: "กรุณาใส่ port" })
                    return
                }
            }
            const smsCommands = {
                sender: "NexExpress",
                msn: values.msn,
                msg: values.command === "B00,1" ? `${values.password},${values.command},${values.ip},${values.port}` : `${values.password},${values.command}`
                // msg: `${values.password},${values.msg.replaceAll(' ', '-')}`
            }
            ApiSms.post(`/v0/send_sms`, smsCommands)
                .then(res => {
                    handleClickVariant({ variant: "success", message: "ส่งข้อความสำเร็จ" })
                })
                .catch(err => {
                    handleClickVariant({ variant: "error", message: "ส่งข้อความไม่สำเร็จ" })
                })
        }
    });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    return (
        <div>
            <Box sx={{ padding: "10px" }} >
                <Container maxWidth="sm" >
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <TextField size="small" label='เบอร์โทร GPS' fullWidth
                                        value={formik.values.msn}
                                        name='msn'
                                        error={Boolean(formik.touched.msn && formik.errors.msn)}
                                        helperText={formik.touched.msn && formik.errors.msn}
                                        {...formik.getFieldProps('msn')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField size="small" label='password' fullWidth
                                        value={formik.values.password}
                                        name='password'
                                        error={Boolean(formik.touched.password && formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        {...formik.getFieldProps('password')}
                                    />
                                </Grid>
                                {
                                    !custom && (
                                        <Grid item xs={12}>
                                            <TextField size="small" label='command' fullWidth
                                                value={formik.values.command}
                                                name='command'
                                                error={Boolean(formik.touched.command && formik.errors.command)}
                                                helperText={formik.touched.command && formik.errors.command}
                                                {...formik.getFieldProps('command')}
                                                select
                                            >
                                                <MenuItem value="F02,21,22,23,24">ตั้งค่าเครื่องรูดบัตร</MenuItem>
                                                <MenuItem value="B08,90"> Set speed limit to 90km</MenuItem>
                                                <MenuItem value="B00,1"> Set IP & Port</MenuItem>
                                            </TextField>
                                        </Grid>
                                    )
                                }
                                {
                                    (!custom && formik.values.command === "B00,1") && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} >
                                                <Grid item xs={12}>
                                                    <TextField size="small" label='IP' fullWidth
                                                        value={formik.values.ip}
                                                        name='ip'
                                                        error={Boolean(formik.touched.ip && formik.errors.ip)}
                                                        helperText={formik.touched.ip && formik.errors.ip}
                                                        {...formik.getFieldProps('ip')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" label='port' fullWidth
                                                        value={formik.values.port}
                                                        name='port'
                                                        error={Boolean(formik.touched.port && formik.errors.port)}
                                                        helperText={formik.touched.port && formik.errors.port}
                                                        {...formik.getFieldProps('port')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    custom && (
                                        <Grid item xs={12}>
                                            <TextField size="small" label='commands' fullWidth
                                                value={formik.values.command}
                                                name='command'
                                                rows={3}
                                                multiline
                                                error={Boolean(formik.touched.command && formik.errors.command)}
                                                helperText={formik.touched.command && formik.errors.command}
                                                {...formik.getFieldProps('command')}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12}>
                                    <Checkbox
                                        checked={custom}
                                        onChange={(event) => setCustom(event.target.checked)}
                                        inputProps={{ 'aria-label': 'custom' }}
                                        label="Custom"
                                    />
                                    <FormLabel>Custom</FormLabel>
                                </Grid>
                                {/**/}
                                <Grid item xs={12} justifyContent="center" justifyItems="center" display="flex">
                                    <Button onClick={() => { formik.submitForm() }}>ส่งข้อความ</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </Container>
            </Box>
        </div>
    )
}
