import React from 'react'
import { Divider, Grid, TextField, Typography, Stack, Box } from '@mui/material'
import SpeedIcon from '@mui/icons-material/Speed';
import { useNavigate } from 'react-router-dom';
import TimelineIcon from '@mui/icons-material/Timeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
export default function MainLogEvents() {
    const menu = [
        { name: "Overspeed by device", icon: <SpeedIcon sx={{ fontSize: "60px" }} />, link: "overspeeddevice" },
        { name: "Overspeed by group", icon: <SpeedIcon sx={{ fontSize: "60px" }} />, link: "overspeedbygroup" },
        { name: "Card Swipe by device", icon: <CreditCardIcon sx={{ fontSize: "60px" }} />, link: "cardswipebydevice" },
        { name: "Card Swipe by group", icon: <CreditCardIcon sx={{ fontSize: "60px" }} />, link: "cardswipebygroup" },
        { name: "Replay History", icon: <TimelineIcon sx={{ fontSize: "60px" }} />, link: "/report/replayv2" },

    ]
    const navigate = useNavigate()
    return (
        <div style={{ padding: "10px" }}>
            <Grid container spacing={1}>
                {
                    menu.map((m, i) => (
                        <Grid item md={4} key={`menu${i}`} >
                            <Box sx={theme => ({
                                boxShadow: 'none',
                                textAlign: 'center',
                                padding: theme.spacing(5, 0),
                                backgroundColor: 'whitesmoke',
                                cursor: 'pointer',
                            })}
                                onClick={() => { navigate(m.link) }}
                            >
                                <Box sx={{
                                    margin: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    {m.icon}
                                </Box>
                                <Typography variant="h4">{m.name}</Typography>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
}
