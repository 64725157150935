import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { useSnackbar } from 'notistack'
export default function BookingCreateCarsOverView({ open, data, handleClose, handleCancel, userId }) {


  const [dataForm, setDataForm] = useState({
    "vehicle_id": "",
    "date_start": "",
    "date_end": "",
    "contact_prefix": "",
    "contact_firstname": "",
    "contact_lastname": "",
    "contact_phone": "",
    "contact_note": ""
  })
  useEffect(() => {
    setDataForm(d => {
      return { ...d, ...data }
    })
  }, [data])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };

  const onSubmit = () => {
    const dataCreateHeader = {
      "booking_userid": userId,
      "type_group": "",
      "type_booking": "",
      "datetime_start": dataForm.date_start,
      "datetime_end": dataForm?.date_end,
      "type_address": "",
      "prefix": "",
      "firstname": "",
      "lastname": "",
      "taxid": "",
      "branch": "",
      "address": "",
      "subdistrict": "",
      "district": "",
      "province": "",
      "postcode": "",
      "phone": "",
      "work_ref": "",
      "contact_prefix": dataForm.contact_prefix,
      "contact_firstname": dataForm.contact_firstname,
      "contact_lastname": dataForm.contact_lastname,
      "contact_phone": dataForm.contact_phone,
      "contact_req": [],
      "contact_note": dataForm.contact_note,
      "status_payment": "",
      "status_trip": "",
      "trip_data": []
    }
    axios.post(`https://booking.sitgps.com/booking/book_h`, { ...dataCreateHeader, contact_req: JSON.stringify(dataCreateHeader.contact_req) })
      .then(res => {
        const booking_id = res.data.booking_id
        const trip = {
          "trip_type": "งานหลัก",
          "trip_start_datetime": dataForm.date_start,
          "trip_end_datetime": dataForm.date_end,
          "booking_id": res.data.booking_id
        }
        axios.post(`https://booking.sitgps.com/booking/create_trip`, trip)
          .then(res => {
            const trip_id = res.data.trip_id
            axios.post(`https://booking.sitgps.com/booking/book_d`, {
              "booking_id": booking_id,
              "trip_id": trip_id,
              "type_car": "",
              "vehicle_id": dataForm.vehicle_id,
              "driver_id": [],
              "reception_id": [],
              "station_start": "",
              "station_start_datetime": dataForm.date_start,
              "station_end_datetime": dataForm.date_end,
              "station_start_flight": "",
              "station_end": "",
              "station_end_flight": "",
              "note": dataForm.note
            })
              .then(res => {
                handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
                handleClose()
              })
              .catch(err => {
                console.log('err', err)
              })
          })
          .catch(err => {
            console.log('err', err)
          })
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setDataForm(d => {
      return { ...d, [name]: value }
    })
  }

  return (
    <Dialog
      open={open}
      // fullScreen
      maxWidth="sm"
      onClose={handleCancel}
      slotProps={{
        backdrop: {
          style: {
            // background: "#F8F8FF", opacity: 0.3
            background: "#a7d8de", opacity: 0.2
            // background: "#000", opacity: 0.1
          }
        }
      }}
    >
      <DialogTitle>สร้าง</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={1} >
          <Grid item xs={6} md={6}>
            <TextField size="small" label='ทะเบียน' value={dataForm.vehicle_id} name='vehicle_id' fullWidth disabled />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField size="small" label='เลขที่อ้างอิง' onChange={onChange} value={dataForm.work_ref} name='work_ref' fullWidth />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField size="small" type="datetime-local" onChange={onChange} value={dataForm.date_start} label='วันที่' name='date_start' fullWidth />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField size="small" type="datetime-local" onChange={onChange} value={dataForm.date_end} label='ถึงวันที่' name='date_end' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography>ผู้ติดต่อ</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <TextField size="small" value={dataForm.contact_prefix} onChange={onChange} label='คำนำหน้า' name='contact_prefix' fullWidth />
              </Grid>
              <Grid item xs={5}>
                <TextField size="small" value={dataForm.contact_firstname} onChange={onChange} label='ชื่อ' name='contact_firstname' fullWidth

                />
              </Grid>
              <Grid item xs={5}>
                <TextField size="small" value={dataForm.contact_lastname} onChange={onChange} label='นามสกุล' name='contact_lastname' fullWidth />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField size="small" value={dataForm.contact_phone} onChange={onChange} label='เบอร์โทร' name='contact_phone' fullWidth />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField size="small" value={dataForm.contact_note} onChange={onChange} label='หมายเหตุ' multiline maxRows={3} minRows={3}
              name='contact_note' fullWidth />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              onClick={onSubmit}
              variant="outlined">บันทึก</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
