import React, { useEffect, useState } from 'react';
import MQTTClient from 'mqtt';

function MonitorHome() {
    const [temperature, setTemperature] = useState(0);

    useEffect(() => {
        const clientId = `mqtt_${Math.random().toString(16).slice(3)}`
        const client = MQTTClient.connect(`http://119.59.114.25`,
            {
                port: '1883',
                protocol: "ws",
                clientId,
                keepalive: 30,
                protocolId: 'MQTT',
                protocolVersion: 4,
                clean: true,
                connectTimeout: 4000,
                username: 'admin',
                password: 'Sit@2021Sit@2021',
                reconnectPeriod: 1000
            }
        )
        client.on('connect', () => {
            client.subscribe('gpscur/111');
        });
        client.on('message', (topic, message) => {
            setTemperature(message.toString());
        });
    }, []);

    return (
        <div>
            <h1>Current Temperature: {temperature}°C</h1>
        </div>
    );
}

export default MonitorHome;