import React from 'react'
import { Box, Card, CardContent, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function BookingSettings() {
    const navigation = useNavigate()

    const onClickNev = (e) => {
        navigation(e)

    }

    return (
        <Box padding={"10px"}>
            <Grid container spacing={1} margin={"10px"}>
                <Grid item md={3}>
                    <Card sx={{ minWidth: 275, cursor: "pointer" }} onClick={() => { onClickNev("events") }}>
                        <CardContent>
                            <Typography >ประเภท Events</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
