import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import axios from 'axios'
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingEventsDialogCreate from './SettingEventsDialogCreate';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import SettingEventsDialogUpdate from './SettingEventsDialogUpdate';
export default function SettingEvents() {
    const [events, setEvents] = useState([])
    const [openCreate, setOpenCreate] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [update, setUpdateData] = useState({})
    useEffect(() => {
        axios.get(`https://booking.sitgps.com/booking/if_event`)
            .then(res => {
                setEvents(res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }, [])

    const getIfEvents = () => {
        axios.get(`https://booking.sitgps.com/booking/if_event`)
            .then(res => {
                setEvents(res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }
    const onCloseCreate = () => {
        setOpenCreate(false)
    }
    const onOpenCreate = () => {
        setOpenCreate(true)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const onHandleDelEvents = ({ event }) => {
        const data = {
            ...event
        }
        axios.delete(`https://booking.sitgps.com/booking/if_event`, { data: data })
            .then(res => {
                handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getIfEvents()
            })
    }

    const onCloseUpdate = () => {
        setOpenUpdate(false)
        setUpdateData({})
    }
    const onOpenUpdate = ({ data }) => {
        setOpenUpdate(true)
        setUpdateData(data)
    }
    return (
        <Box>
            <SettingEventsDialogCreate open={openCreate} onClose={onCloseCreate} getData={getIfEvents} />
            <SettingEventsDialogUpdate data={update} open={openUpdate} onClose={onCloseUpdate} getData={getIfEvents} />
            <Box padding="10px" justifyContent="end" display="flex">
                <IconButton onClick={onOpenCreate}><EditNoteIcon /></IconButton>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>event code</TableCell>
                        <TableCell>event name</TableCell>
                        <TableCell>type</TableCell>
                        <TableCell>note</TableCell>
                        {/* <TableCell>status</TableCell> */}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        events.map((event, index) => {
                            return (
                                <TableRow key={`work-${index}`}>
                                    <TableCell>{event.event_code}</TableCell>
                                    <TableCell>{event.event_name}</TableCell>
                                    <TableCell>{event.type}</TableCell>
                                    <TableCell>{event.note}</TableCell>
                                    {/* <TableCell>{event.status}</TableCell> */}
                                    <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                        <Stack direction="row">
                                            <IconButton size="small" onClick={() => onOpenUpdate({ data: event })}><EditNoteIcon fontSize="small" /></IconButton>
                                            <IconButton size="small"
                                                onClick={() => {
                                                    handleSnackWithAction({
                                                        message: "ต้องการลบข้อมูล",
                                                        onHandle: () => onHandleDelEvents({ event: event })
                                                    })
                                                }}
                                            ><DeleteForeverIcon fontSize="small" /></IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    )
}
