import React from 'react'
import { Box, Collapse, TextField, Stack, Table, TableBody, TableHead, TableRow, TableCell, Button } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useState } from 'react'
import axios from 'axios'
import { fDateTo } from '../../utils/formatTime'

export default function BookingReport() {
    const [dateSelect, setDateSelect] = useState(null)
    const [dataReport, setDataReport] = useState(null)
    const getReport = () => {
        axios.get(`https://booking.sitgps.com/booking/report_1?date=${fDateTo(dateSelect, "yyyy-MM-dd")}`)
            .then(res => {
                if (res.data?.date) {
                    setDataReport(res.data)
                } else {
                    setDataReport(null)
                }
            })
            .catch(err => {
                console.log('err', err)
            })
    }
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ padding: "10px" }}>
                    <Box marginY={"10px"}>
                        <Stack direction="row" spacing={1}>
                            <DesktopDatePicker
                                label="วันที่"
                                inputFormat="dd/MM/yyyy"
                                value={dateSelect}
                                name='dateSelect'
                                onChange={(value) => { setDateSelect(value) }}
                                renderInput={(params) => <TextField size="small" label='วันที่' name='dateSelect' {...params} />}
                            />
                            <Button onClick={getReport} variant="contained" > ค้นหา </Button>
                        </Stack>
                    </Box>
                    <Box>
                        {dataReport !== null && (

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>วันที่ : {fDateTo(dataReport?.date, "dd/MM/yyyy")}</TableCell>
                                        <TableCell colSpan={2}>จำนวนทั้งหมด : {dataReport?.booking_count}</TableCell>
                                        <TableCell colSpan={2}>จำนวนรถที่ใช้งานทั้งหมด : {dataReport?.vehicle_count}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataReport?.data?.map((booking, index) => {
                                            return (
                                                <React.Fragment key={`booking-${index}`}>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: "bold" }}>เลขที่ใบจอง </TableCell>
                                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>วันที่</TableCell>
                                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>ถึงวันที่</TableCell>
                                                        <TableCell sx={{ fontWeight: "bold" }}>สถานะ</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{booking.booking_id}</TableCell>
                                                        <TableCell colSpan={2}>{fDateTo(booking.datetime_start, "dd/MM/yyyy")}</TableCell>
                                                        <TableCell colSpan={2}>{fDateTo(booking.datetime_end, "dd/MM/yyyy")}</TableCell>
                                                        <TableCell>{booking.status}</TableCell>
                                                    </TableRow>
                                                    {
                                                        booking?.trip_data?.map((trip, index) => {
                                                            return (
                                                                <React.Fragment key={`trip_data-${index}`}>
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>เลขที่ทริป</TableCell>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>ทะเบียนรถ</TableCell>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>พนักงานขับรถ</TableCell>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>พนักงานต้อนรับ</TableCell>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>วันที่เริ่ม</TableCell>
                                                                        <TableCell sx={{ fontWeight: "bold" }}>วันที่สิ้นสุด</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>{trip.trip_id}</TableCell>
                                                                        <TableCell colSpan={5}></TableCell>
                                                                    </TableRow>
                                                                    {
                                                                        trip?.data?.map((data, index) => {

                                                                            return (
                                                                                <TableRow key={`trip-data-${index}`}>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell>{data.vehicle_id}</TableCell>
                                                                                    <TableCell>{data.driver_id}</TableCell>
                                                                                    <TableCell>{data.reception_id}</TableCell>
                                                                                    <TableCell>{fDateTo(data.station_start_datetime, "dd/MM/yyyy")}</TableCell>
                                                                                    <TableCell>{fDateTo(data.station_end_datetime, "dd/MM/yyyy")}</TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        )}
                    </Box>
                </Box>
            </LocalizationProvider>
        </div>
    )
}
